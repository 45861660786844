<template>
	<v-container fluid>
	<headerVue :title="$t('menu.editEquipmentType')" />
		<v-row>
			<v-col v-if="loading" cols="12" style="text-align:center;">
				<v-progress-circular
				:size="70"
				:width="7"
				color="purple"
				indeterminate></v-progress-circular>
			</v-col>
			<v-col cols="12" v-if="!loading">
				<v-card>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="2">{{ $t("description") }}</v-col>
						<v-col cols="2">
							<v-text-field filled v-model="name"></v-text-field>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="!loading">
			<v-col cols="12" align-self="center">
				<v-btn @click="back">{{ $t("back") }}</v-btn>
				<v-btn color="primary" @click="update"> {{ $t("save") }}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import headerVue from "../../components/header.vue"
export default {
	components: {
		headerVue,
	},
	data() {
		return {
			loading: true,
			name: "Tipo equipo 1",
			id: null,
		}
	},
  	methods: {
		back() {

			this.$router.push( "/tiposequipo" );
		},
		update() {
			
			const headerCurrent = {
				'Content-type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true'
			}
			var raw = JSON.stringify({
				"id": this.id,
				"nombre": this.name
			});

			axios.put('https://consubanco.azurewebsites.net/api/EquipoTipo/'+this.id, raw, {
				headers: headerCurrent
			})
			.then((response) => {
				console.info("update => ", response);

				this.$router.push("/tiposequipo");
			})
			.catch((error) => {
				console.warn("save => ", error);
			});
		},
	},
	created(){

		this.id = this.$route.params.id;
		
		axios.get( 'https://consubanco.azurewebsites.net/api/EquipoTipo/'+this.id )
		.then(resp => {
			
			this.name = resp.data.nombre;
			this.loading = false;
		})
		.catch(err => {
			
			console.error(err);
		});
	},
}
</script>